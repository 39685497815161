import { render, staticRenderFns } from "./ProfileBdetails.vue?vue&type=template&id=1a1deb8a&scoped=true&"
import script from "./ProfileBdetails.vue?vue&type=script&lang=js&"
export * from "./ProfileBdetails.vue?vue&type=script&lang=js&"
import style0 from "./ProfileBdetails.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ProfileBdetails.vue?vue&type=style&index=1&id=1a1deb8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a1deb8a",
  null
  
)

export default component.exports